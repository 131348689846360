var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "page-path": _vm.ae$pagePath,
        title: _vm.ae$title,
        "toolbar-actions": _vm.toolbarActions,
        widget: _vm.widget,
        "title-icon": "mdi-decagram",
        "initial-width": "4",
      },
      on: {
        "toolbar-action": function ($event) {
          return _vm.toolbarMethod($event)
        },
      },
    },
    [
      _c("asyent-search", {
        attrs: {
          "filter-dialog": _vm.filterDialog,
          filters: _vm.filters,
          headers: _vm.headers,
          "reload-counter": _vm.reloadCounter,
          "search-method": _vm.search,
          "export-counter": _vm.exportCounter,
          "export-file-name": "access-roles",
        },
        on: {
          "filter-dialog": function ($event) {
            _vm.filterDialog = $event
          },
        },
      }),
      _c("div", { staticClass: "bottom-space" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }